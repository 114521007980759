import React from "react"
import WhatLayout from "../../components/WhatLayout";
import bild01 from "../../images/what/tribute/johnny-depp.png";
import bild02 from "../../images/what/tribute/kurt-cobain-in-wein.png";
//import bild03 from "../../images/what/tribute/nina-hagen.png";
import bild04 from "../../images/what/tribute/joey.png";
import bild05 from "../../images/what/tribute/joker.png";
import bild06 from "../../images/what/tribute/spock-kirk.png";
import bild07 from "../../images/what/tribute/trent.png";
import bild08 from "../../images/what/tribute/zombieboy.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Tribute"}
      images={[
        bild01,
        bild02,
//        bild03,
        bild04,
        bild05,
        bild06,
        bild07,
        bild08
      ]}
    />
  );

};

export default Page;
